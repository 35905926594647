@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700;900&display=swap");

html,
body {
  font-family: "Roboto", sans-serif !important;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.logo-menu {
  max-width: 70px;
  align-self: center;
  margin-bottom: 20px;
}

.menu {
  padding-top: 20px;
  background-color: #192440;
  min-height: 100vh;
}

.menu a {
  color: #fff;
  font-weight: 700;
}

.nav-item {
  margin-bottom: 20px;
}

.nav-item svg {
  margin-right: 10px;
}

.safehut-menu a {
  text-decoration: none;
}

.main-background {
  background-color: #efefef;
  padding: 40px 0px;
}

.login-container {
  background-color: #efefef;
  min-height: 100vh;
  padding-top: 5rem;
}

.login {
  margin-top: 20px;
  padding: 30px !important;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
}

.login form label {
  font-weight: 700;
}

.navbar-safehut {
  background-color: #192440 !important;
}

.logoutText {
  cursor: pointer;
  color: #fff !important;
}

.main-content {
  background-color: #efefef;
  min-height: 100vh;
  padding: 30px;
}

/* ACCOUNTS */

.accounts-header h4 {
  margin-bottom: 0px;
}

.accounts-content {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
}

.account-element {
  padding: 10px 10px;
}

.account-element:nth-child(even) {
  background-color: #efefef;
}

.account-element h5 {
  margin-bottom: 0px;
  cursor: pointer;
  color: #192440;
  font-weight: bold;
  text-decoration: underline;
}

.new-account-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.delete-account-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.delete-account-form button {
  margin-right: 10px;
}

.new-account-form form button {
  margin-right: 10px;
}

.new-account-form form label {
  font-weight: 700;
}

.icon {
  font-size: 2rem;
  color: #03acbe;
  cursor: pointer;
}

/* RESIDENCES */

.residences-content {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
}

.residences-header h4 {
  margin-bottom: 0px;
}

.residence-element {
  padding: 10px 10px;
}

.residence-element:nth-child(even) {
  background-color: #efefef;
}

.residence-element h5 {
  margin-bottom: 0px;
  cursor: pointer;
  color: #192440;
  font-weight: bold;
  text-decoration: underline;
}

.new-residence-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.new-residence-form form button {
  margin-right: 10px;
}

.new-residence-form form label {
  font-weight: 700;
}

.delete-residence-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.delete-residence-form button {
  margin-right: 10px;
}

.residence-details {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
}

.residence-address h4 {
  font-weight: 700;
}

.options-panel {
  font-size: 2rem;
  color: #03acbe;
}

.options-panel svg {
  cursor: pointer;
  margin: 0px 10px;
}

input[type="text"] {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.residence-users h5 {
  font-weight: bold;
}

.residence-user {
  padding: 10px 15px;
}

.residence-user:nth-child(even) {
  background-color: #efefef;
}

/* NOTICES */
.new-notice-form {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
}

.notices h4 {
  margin-bottom: 0px;
}

.notice-element {
  padding: 20px;
  border-radius: 10px;
  background-color: #03acbe;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
  color: #fff;
}

.notices-empty {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
  text-align: center;
}

.notice-date {
  font-weight: 700;
}

.notices-header {
  margin-bottom: 10px;
}

.new-notice-form form label {
  font-weight: bold;
}

.new-notice-form form button {
  margin-right: 10px;
}

.delete-icon svg {
  cursor: pointer;
}

/* USERS */
.new-user-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.new-user-form form button {
  margin-right: 10px;
}

.new-user-form form label {
  font-weight: 700;
}

.delete-user-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.delete-user-form button {
  margin-right: 10px;
}

/* PAYMENTS */
.manual-payments-header h4 {
  margin-bottom: 0px;
}

.manual-payments-content {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
}

.manual-payment-element {
  padding: 5px 5px;
}

.manual-payment-element h6 {
  margin-bottom: 0px;
}

.manual-payment-element:nth-child(even) {
  background-color: #efefef;
}

.manual-payment-element h5 {
  margin-bottom: 0px;
}

.manual-payments-content h4 {
  margin-bottom: 0px;
  color: #aaa;
}

.manual-payment-element p {
  margin-bottom: 0px;
  display: inline;
  color: rgb(13, 110, 253);
  cursor: pointer;
  text-decoration: underline;
}

.edit-manual-payment-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.edit-manual-payment-form form button {
  margin-right: 10px;
}

.edit-manual-payment-form form label {
  font-weight: 700;
}

.new-manual-payment-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.new-manual-payment-form form button {
  margin-right: 10px;
}

.new-manual-payment-form form label {
  font-weight: 700;
}

/* DASHBOARD */
.dashboard-residents h2.counter {
  font-size: 4rem;
  font-weight: bold;
}

.dashboard-residents button {
  margin-bottom: 10px;
}

.dashboard-residents {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
  min-height: 220px;
}

.dashboard-money {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
}

.sublime-text {
  color: #aaa;
}

/* REPORTS */
.reports-header h4 {
  margin-bottom: 0px;
}

.reports-content h4 {
  margin-bottom: 0px;
  color: #bbb;
}

.report-element p {
  display: inline;
  color: #f64e60;
  cursor: pointer;
  text-decoration: none;
}

.report-element svg {
  color: #f64e60;
  cursor: pointer;
}

.reports-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
}

.report-element {
  padding: 10px;
}

.report-element h5 {
  margin-bottom: 0px;
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
  color: #192440;
}

.report-element:nth-child(even) {
  background-color: #efefef;
}

.new-report-form {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
}

.new-report-form form button {
  margin-right: 10px;
}

.new-report-form form label {
  font-weight: 700;
}

.survey-report {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
}

.pie-chart-element {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.pie-chart-element-content {
  margin-left: auto;
  margin-right: auto;
}

.finance-report {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
}

.revenue {
  background-color: #d7f9ef;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
  border-radius: 10px;
}

.revenue p {
  margin-bottom: 0px;
}

.revenue svg {
  color: #0bb783;
  font-weight: bold;
}

.expenses svg {
  color: #f64e60;
  font-weight: bold;
}

.expenses {
  background-color: #ffe2e5;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
  border-radius: 10px;
}

.expenses p {
  margin-bottom: 0px;
}

.revenue-amount {
  color: #0bb783;
  font-weight: bold;
}

.revenue-date {
  color: #0bb783;
}

.expense-amount {
  color: #f64e60;
  font-weight: bold;
}

.expense-date {
  color: #f64e60;
}

.timeline-report {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
}

.ledger-event p {
  margin-bottom: 0px;
}

.ledger-event h6 {
  margin-bottom: 0px;
  font-weight: bold;
}

.ledger-event p.helper-text {
  color: #555;
  font-size: 0.8rem;
}

.ledger-event p.key-text {
  color: #333;
  font-size: 0.9rem;
}

.ledger-event p.dateTime {
  font-weight: bold;
}

.ledger-event p.residence {
  color: #555;
  font-size: 0.8rem;
  margin-bottom: 0px;
}

.ledger-event {
  padding: 10px 25px 10px 10px;
}

.ledger-event:nth-child(even) {
  background-color: #c1f4f7;
  border-radius: 5px;
}

/* SUPPORT */
.support-form {
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
  border-radius: 8px;
}

.support-form form label {
  font-weight: bold;
}

/* SUCCESS */
.success {
  min-height: 90vh;
}

.success-container {
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
  border-radius: 8px;
}

.success-container img {
  margin-bottom: 20px;
}

.residences-content h6 {
  font-weight: bold;
}

.residences-content p {
  font-weight: bold;
  margin: 0px;
}

.residences-total {
  padding: 0px 25px;
}

.residences-total h5,
.residences-total p {
  font-weight: bold;
}

/* DISCLAIMER */
.disclaimer p {
  color: #555;
}

/* TICKETS */
.new-ticket-form {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
}

.tickets h4 {
  margin-bottom: 0px;
}

.ticket-element-open {
  padding: 20px;
  border-radius: 10px;
  background-color: #0dcaf0;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
  color: #fff;
}

.ticket-element-open a {
  text-decoration: underline;
  color: #fff;
}

.ticket-element-closed {
  padding: 20px;
  border-radius: 10px;
  background-color: #6c757d;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
  color: #fff;
}

.ticket-element-closed a {
  text-decoration: underline;
  color: #fff;
}

.ticket-element-pending {
  padding: 20px;
  border-radius: 10px;
  background-color: #0d6efd;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
  color: #fff;
}

.ticket-element-pending a {
  text-decoration: underline;
  color: #fff;
}

.tickets-empty {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
  text-align: center;
}

.ticket-date {
  font-weight: 700;
}

.tickets-header {
  margin-bottom: 10px;
}

.new-ticket-form form label {
  font-weight: bold;
}

.new-ticket-form form button {
  margin-right: 10px;
}

p.evidence {
  cursor: pointer;
  color: #0d6efd;
}

/* EXPENSES */
.expenses-header h4 {
  margin-bottom: 0px;
}

.expenses-content {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
}

.expense-element {
  padding: 10px 10px;
}

.expense-element:nth-child(even) {
  background-color: #efefef;
}

.expense-element h5 {
  margin-bottom: 0px;
  cursor: pointer;
  color: #192440;
  font-weight: bold;
  text-decoration: underline;
}

.new-expense-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.delete-expense-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.delete-expense-form button {
  margin-right: 10px;
}

.new-expense-form form button {
  margin-right: 10px;
}

.new-expense-form form label {
  font-weight: 700;
}

@media only screen and (min-width: 400px) {
  .menu {
    padding-top: 20px;
    background-color: #192440;
    min-height: auto !important;
  }

  .dashboard-residents {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
    min-height: 220px;
    margin-bottom: 2rem;
  }
}
